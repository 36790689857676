import type { KitMeta, StoredSound } from "~/types";
import Dexie, { type Table } from "dexie";

export class IDB extends Dexie {
  // 'files' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  files!: Table<StoredSound>;
  metadata!: Table<KitMeta>;
  constructor() {
    super("sound-kit");
    this.version(1).stores({
      files: "++id", // Primary key and indexed props
      metadata: "publicId",
    });
  }
}

export const idb = new IDB();
