import * as React from "react";
import type { SVGProps } from "react";
const SvgIconDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path
      id="Path-57"
      fillRule="nonzero"
      d="M1.333 8.667C1.333 8.333 1.667 8 2 8s.667.333.667.667V12c0 .736.597 1.333 1.333 1.333h8c.736 0 1.333-.597 1.333-1.333V8.667a.667.667 0 0 1 1.334 0V12A2.667 2.667 0 0 1 12 14.667H4A2.667 2.667 0 0 1 1.333 12z"
    />
    <path
      id="Path"
      d="m8.446 10.496 3.333-3a.667.667 0 1 0-.892-.992l-2.221 2v-7.17a.667.667 0 0 0-1.333 0V8.39L5.138 6.195a.667.667 0 1 0-.943.943l3.334 3.333c.25.251.653.262.917.025"
    />
  </svg>
);
export default SvgIconDownload;
